import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Select,
  Slide,
  Slider,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useRootStore } from "providers/RootStoreProvider";
import { useTranslation } from "react-i18next";
import { toJS } from "mobx";
import CanvasWrapper from "components/utils/CanvasWrapper";
import { Space } from "interfaces/Building";
import moment from "moment";
import { bhpColor, settings } from "styles/globals";
import CloseIcon from "@material-ui/icons/Close";
import { ConfirmDialog } from "components/utils/ConfirmDialog";
import { hasBookableSpace, hasBookableSpaceCapacity } from "utils/locations";
import { TeamParticipant } from "interfaces/Teams";
import StarBorderTwoToneIcon from "@material-ui/icons/StarBorderTwoTone";
import StarTwoToneIcon from "@material-ui/icons/StarTwoTone";
import BuildTwoToneIcon from "@material-ui/icons/BuildTwoTone";
import HeaderImage from "assets/headerImage.png";
import {
  addMinutes,
  BasicBooking,
  bookingPeriodStatus,
  getBuildingBookingSpecs,
  getBookedSpaceName,
  getBookingsTable,
  getSortedBookings,
  getTimeStringFromDate,
  isTimeAvailable,
  Period,
  getBookingsForDate,
  getSpacesBookedAtThisTime,
  isFireWardenBooked,
  isFirstAidOfficerBooked,
  DEFAULT_INCREMENT,
  BOOKING_DURATION_MAX,
  getDaysApart,
  DEFAULT_START_TIME,
  getBoolean,
  getDaysBetween,
  MAX_DAYS_AHEAD_TO_BOOK,
  getBookingsAtThisTime,
  getPeriodStatus,
  bookingsAtThisTime,
  getLengthOfBooking,
  getLengthOfPeriod,
  getLengthOfSelection,
  BOOKING_DURATION_MIN,
  getDateTimeFromStringsAndTimeZone,
  getEndString,
} from "utils/hourlyBookings";
import { PopupInformationWidget } from "components/settings/PopupInformationWidget";
import {
  DeskColour,
  label_tooltip,
  spot_available,
  spot_focussed,
  spot_officer,
  spot_partly,
  spot_team,
  spot_unavailable,
  tolerance,
} from "./Floorplan/Constants";
import {
  colorShift,
  correctCoordinates,
  drawCross,
  drawExtras,
  drawHoverDot,
  drawIcon,
  drawKey,
  drawLabel,
  drawSpot,
  getBoxPosition,
  getColor,
  getColorRGB,
  getNumberSetter,
  getScales,
  Scale,
  scaledX,
  scaledY,
} from "./Floorplan/Drawing";
import React from "react";
import { TransitionProps } from "@material-ui/core/transitions";
import WarningTwoToneIcon from "@material-ui/icons/WarningTwoTone";
import { ServiceContextMenu } from "./Floorplan/ServiceContextMenu";
import { deskTypeIds, getSpaceTypeDetail } from "./enhancedFloorplan/OtherTypes";

interface InteractiveFloorplanProps {
  floorId?: number | null;
  spaceId?: number;
  showSpaceId?: number;
  floorSpaces?: Space[];
  date: string;
  buildingId?: number;
  onSpaceChange?: any;
  onDeskHoursChange?: any;
  doBooking?: any;
  match?: any;
  onclose?: any;
  disableEditing?: boolean;
  showLabels?: boolean;
  showButton?: boolean;
  teamMembers?: TeamParticipant[];
  bookingStart?: string;
  bookingEnd?: string;
  startSelector?: any;
  endSelector?: any;
  deskHours: Period;
  isWayFinder?: boolean;
  spaceCount?: any;
  deskFilters?: any;
}

const useStyles = makeStyles((theme) => ({
  inlineIcon: {
    display: "inline-flex",
    alignItems: "center",
    color: bhpColor.orange1,
    fontWeight: "bold",
    paddingRight: 4,
    cursor: "pointer",
  },
  timeSelector: {
    padding: 0,
    "& .MuiSelect-root": {
      padding: "4px",
      width: "80px",
      fontSize: "14px",
    },
    "& .MuiSelect-iconOutlined": {
      right: 0,
    },
  },
  cancelButton: {
    "&:hover": {
      backgroundColor: `${bhpColor.orange4}50`,
    },
  },
  dialogTitle: {
    backgroundImage: `url(${HeaderImage})`,
    color: bhpColor.white,
    "& h2": {
      fontWeight: "bold",
      "& svg": {
        verticalAlign: "text-top",
      },
    },
  },
  content: {
    marginBottom: theme.spacing(2),
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Convert a local date (in a given timezone) to a UTC Date
const convertLocalToUTC = (localDate: Date, tz: string): Date => {
  return moment.tz(localDate, tz).utc().toDate();
};

// Returns the number of days between two UTC dates.
const getNoDaysBetweenUTC = (currentUTC: Date, bookingUTC: Date): number => {
  return moment.utc(bookingUTC).diff(moment.utc(currentUTC), "days");
};

// Fetch the current UTC datetime
const fetchCurrentDateFromBackend = async (): Promise<Date> => {
  try {
    const response = await fetch("/api/server_time/current_time/");
    const data = await response.json();
    return new Date(data.current_datetime);
  } catch (error) {
    console.error("Error fetching current date from backend:", error);
    // Fallback to browser date if API call fails.
    return new Date();
  }
};

// introduced showSpaceId to enable bullseye for show desk
const InteractiveFloorplan = observer((props: InteractiveFloorplanProps) => {
  const {
    buildingId,
    floorId,
    spaceId,
    onSpaceChange,
    onDeskHoursChange,
    doBooking,
    date,
    onclose,
    disableEditing,
    showSpaceId,
    showLabels,
    teamMembers,
    showButton,
    deskHours,
    bookingStart,
    bookingEnd,
    startSelector,
    endSelector,
    isWayFinder,
    spaceCount,
    deskFilters,
  } = props;

  const store = useRootStore();
  const history = useHistory();
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const propsFloorId: string | null = floorId ? `${floorId}` : null;
  const propsSpaceId: number | undefined = spaceId;

  store.bookingStore.setDirtyFloor();
  if (propsFloorId && `${propsFloorId}` !== `${store.buildingStore.currentFloorId}`) {
    store.buildingStore.setCurrentFloor(Number(propsFloorId));
  }

  const [hasLegacyFloorplan, setHasLegacyFloorplan] = useState(
    store.buildingStore.currentFloor && store.buildingStore.currentFloor.floor_plan ? true : false
  );

  const getFloorImageModule = (floorId: string | number | null | undefined) => {
    if (!floorId) return null;
    let module;
    try {
      module = require(`assets/floorplans/sv_${floorId}.png`);
    } catch {
      return null;
    }
    return module;
  };

  const [floorImage, setFloorImage] = useState<any>(
    store.buildingStore.currentFloor &&
      store.buildingStore.currentFloor.floor_plan &&
      `${store.buildingStore.currentFloor.floor_plan}` !== ""
      ? store.buildingStore.currentFloor.floor_plan
      : propsFloorId
      ? getFloorImageModule(propsFloorId)
      : null
  );

  const labels = showLabels;

  //#region Hourly booking
  const hourlyBookings = true; // disable the hourly bookings interface
  const [buildingBookingSpecs, setBuildingBookingspecs] = useState(
    getBuildingBookingSpecs(store.buildingStore)
  );
  const theBuilding = store.buildingStore.getBuilding(buildingId!);
  const timezone = theBuilding?.building_timezone;
  if (!timezone) return null;

  const momentFrom = moment.tz(`${date} 00:00:00`, timezone);
  const momentTo = moment.tz(`${date} 23:59:59`, timezone);
  const periodFrom = momentFrom.toDate();
  const periodTo = momentTo.toDate();
  const dayPeriod = { start: periodFrom, end: periodTo };

  const [wayFinderPeriod, setWayFinderPeriod] = useState({
    start: moment.tz(`${date} 09:00:00`, timezone).toDate(),
    end: moment.tz(`${date} 09:15:00`, timezone).toDate(),
  });

  const [endSelectorStart, setEndSelectorStart] = useState(
    moment
      .tz(deskHours ? deskHours.start : DEFAULT_START_TIME, theBuilding.building_timezone!)
      .add(theBuilding.minimum_booking_time, "minutes")
      .format("YYYY-MM-DD HH:mm")
  );
  const [endSelectorEnd, setEndSelectorEnd] = useState(
    moment
      .tz(deskHours ? deskHours.start : DEFAULT_START_TIME, theBuilding.building_timezone!)
      .add(theBuilding.maximum_booking_time, "minutes")
      .format("YYYY-MM-DD HH:mm")
  );

  const localClick = useRef(false);

  const getConsisitentTime = (time: string | undefined) => {
    return time ? moment(`${moment(new Date()).format("YYYY-MM-YY")} ${time}`).format("h:mma") : "";
  };

  const drawBookings = (spc: Space, date: string, timezone: string | undefined) => {
    const theBookings = getBookingsTable(
      date!,
      floorbookings,
      spc.id,
      buildingBookingSpecs,
      store.buildingStore.currentBuilding?.building_timezone
    );
    if (theBookings.length > 0) {
      return (
        <Grid container key={`br0-0-0`}>
          {theBookings.map((thisBooking, idx) => {
            if (!thisBooking) return "";
            const color =
              thisBooking.id === 0
                ? spot_available.hex
                : inThisTeam(thisBooking.user_id || 0)
                ? spot_team.hex
                : spot_unavailable.hex;
            return (
              <>
                <Grid
                  item
                  style={{ whiteSpace: "nowrap", display: "flex", color: color }}
                  key={`br2-${thisBooking.id}${idx}`}
                  xs={7}
                >
                  <div
                    style={{
                      width: "5px",
                      height: "90%",
                      backgroundColor: color,
                      display: "inline-block",
                      marginRight: "4px",
                    }}
                  ></div>
                  {`${getConsisitentTime(
                    thisBooking.booking_details?.start_time_local
                  )} - ${getConsisitentTime(thisBooking.booking_details?.end_time_local)}`}
                </Grid>
                <Grid
                  item
                  xs={5}
                  key={`br3-${thisBooking.id}${idx}`}
                  style={{
                    color: color,
                    whiteSpace: "nowrap",
                    overflowY: "hidden",
                    overflow: "hidden",
                  }}
                >
                  {thisBooking.user_name ? thisBooking.user_name : "Available"}
                </Grid>
              </>
            );
          })}
        </Grid>
      );
    } else return "";
  };

  //#endregion

  const canvasWidth = 1024;
  const [canvasHeight, setCanvasHeight] = useState(1024);
  const [yRatio, setYRatio] = useState(1);

  const onImgLoad = (img: any) => {
    const imgSource = img.target;
    const pixelWidth = imgSource.offsetWidth;
    const pixelHeight = imgSource.offsetHeight;
    const yRatio = pixelHeight / pixelWidth;
    setCanvasHeight(pixelHeight);
    setYRatio(yRatio);
  };

  const canvasRef = useRef(null);

  const floorplanSpecs = store.buildingStore.getHardCodedFloorplanSpecs(buildingId);

  // #region Stuff for scaling

  const theScales = getScales(`${store.buildingStore.currentFloor?.id}`, floorplanSpecs);

  const spaceRadius = theScales.spaceRadius || 10;
  // #endregion

  const [bookingSelected, setBookingSelected] = useState<BasicBooking | null>(null);

  const [context, setContext] = useState();
  const theContext = (ctx: any) => setContext(ctx);

  const pointIsNear = (
    spaceX: number,
    spaceY: number,
    clickX: number,
    clickY: number,
    theScales: { scale: { x: number; y: number }; offset: { x: number; y: number } } | null
  ) => {
    const correctedX: number = theScales ? Math.round(scaledX(spaceX, theScales)) : spaceX;
    const correctedY: number = theScales ? Math.round(scaledY(spaceY, theScales)) : spaceY;
    const boundingBox: { tl: { x: number; y: number }; br: { x: number; y: number } } = {
      tl: { x: correctedX - spaceRadius, y: correctedY - spaceRadius },
      br: { x: correctedX + spaceRadius, y: correctedY + spaceRadius },
    };
    return (
      clickX > boundingBox.tl.x &&
      clickX < boundingBox.br.x &&
      clickY > boundingBox.tl.y &&
      clickY < boundingBox.br.y
    );
  };

  const [didClick, setDidClick] = useState(false);
  const [clickPosition, setClickPosition] = useState<{ x: number; y: number }>({ x: -1000, y: 0 });
  const [hotSpotPosition, setHotSpotPosition] = useState<{
    x: number;
    y: number;
    space?: Space;
  } | null>(null);

  const findSpaceNearPoint = (x: number, y: number, spaces: Space[], scales: Scale) => {
    if (spaces && x && y) {
      const theSpace = spaces.find((spc) => {
        const correctedPoint = getPoint(spc, scales);
        return correctedPoint ? pointIsNear(correctedPoint.x, correctedPoint.y, x, y, null) : null;
      });
      return theSpace;
    }
    return null;
  };

  const mouseClick = (evt: any) => {
    const clickOffsetX = evt.offsetX;
    const clickOffsetY = evt.offsetY;
    if (clickOffsetX && clickOffsetY) {
      setDidClick(true);
      const floorToUse = `${store.buildingStore.currentFloorId}`;
      const theFloorSpaces = store.buildingStore.floorSpaces[floorToUse];
      const floorScales = getScales(floorToUse, floorplanSpecs);
      const foundSpace = findSpaceNearPoint(
        clickOffsetX,
        clickOffsetY,
        theFloorSpaces,
        floorScales
      );
      if (foundSpace) {
        const clickPos = getPoint(foundSpace, floorScales);
        if (clickPos) {
          const boxPosition = getBoxPosition(clickPos, foundSpace, infoBoxRef, box, canvasWidth);
          setClickPosition({ x: boxPosition.x, y: boxPosition.y });
          setClickedSpace(foundSpace);
          if (onSpaceChange) onSpaceChange(foundSpace.id);
        }
      } else {
        closeInfoBox();
      }
    }
  };

  /**
   * if has local points returns the calibrated coord
   * otherwise scales off the sv json
   * @param theSpace
   * @param floorScales
   * @returns {x,y}
   */
  const getPoint = (theSpace: Space, floorScales: Scale): { x: number; y: number } => {
    const pointToReturn =
      theSpace.corrected_coordinates_x && theSpace.corrected_coordinates_y
        ? { x: theSpace.corrected_coordinates_x, y: theSpace.corrected_coordinates_y }
        : correctCoordinates({ ...theSpace.coordinates, id: theSpace.id }, floorScales);
    return pointToReturn;
  };

  const infoBoxRef = useRef(null);

  /**
   * checks if userId is in the list of team members
   * @param userId - user ID
   * @returns true if in team otherwise false
   */
  const inThisTeam = (userId?: number) => {
    if (userId === store.userStore.me?.id) return false;
    const response =
      !userId || !teamMembers
        ? false
        : teamMembers.filter((mem) => `${mem.user_id}` === `${userId}`).length > 0;
    return response;
  };

  /**
   * set hotSpotPosition to coordinates of space if mouseOver
   * or null if not on a spot
   * @param mouseX - evt.offsetX
   * @param mouseY - evt.offsetY
   * @param ctx - canvas context
   */
  const checkMouseLocation = (mouseX: number, mouseY: number, ctx: any) => {
    if (store.buildingStore.currentFloorId) {
      const floorToUse = `${store.buildingStore.currentFloorId}`;
      const theFloorSpaces = store.buildingStore.floorSpaces[floorToUse].filter((spt) =>
        deskTypeIds.includes(spt.space_type?.id || 0)
      );
      const floorScales = getScales(floorToUse, floorplanSpecs);
      const foundSpace = findSpaceNearPoint(mouseX, mouseY, theFloorSpaces, floorScales);
      if (foundSpace) {
        const labelPos = getPoint(foundSpace, floorScales);
        if (labelPos) {
          ctx.canvas.style.cursor = "pointer";
          setHotSpotPosition({ ...labelPos, space: foundSpace });
        }
      } else {
        setHotSpotPosition(null);
        ctx.canvas.style.cursor = "default";
      }
    }
  };

  const draw = (ctx: any) => {
    if (propsFloorId) {
      let img = new Image();
      img.src = propsFloorId && floorImage;
      ctx.clearRect(0, 0, canvasWidth, canvasHeight);
      ctx.drawImage(img, 0, 0, canvasWidth, canvasHeight);

      const imageData = ctx.getImageData(0, 0, canvasWidth, canvasHeight);
      const shiftedImage = colorShift(imageData, tolerance.black, true);
      if (clickedSpace && didClick) {
        const clickedImage = colorShift(shiftedImage, tolerance.white, false);
        ctx.putImageData(clickedImage, 0, 0);
      } else {
        ctx.putImageData(shiftedImage, 0, 0);
      }

      // drop a tell-tale so we can see it is using local points
      // 2px dot bottom left of floorplan image
      if (hasLocalPoints) {
        const pointHeight = 2;
        ctx.fillStyle = bhpColor.purple4;
        ctx.fillRect(0, canvasHeight - pointHeight, pointHeight, pointHeight);
      }

      const theScales = getScales(propsFloorId, floorplanSpecs);
      const hotSpotColor =
        hotSpotPosition && hotSpotPosition.space
          ? getColor(
              hotSpotPosition.space,
              false,
              null,
              getBoolean(
                `${hotSpotPosition.space.id}` === `${propsSpaceId}` && didClick && clickedSpace
              ),
              floorbookings,
              deskHours,
              buildingBookingSpecs.gap,
              buildingBookingSpecs.min_duration,
              store.userStore.me!.id,
              isWayFinder,
              wayFinderPeriod
            )
          : spot_available;

      if (!store.buildingStore.isLoadingSpaces) {
        if (store.buildingStore.floorSpaces && store.buildingStore.floorSpaces[propsFloorId]) {
          if (
            hotSpotPosition &&
            hotSpotPosition.space &&
            deskTypeIds.includes(hotSpotPosition.space.space_type?.id || 0)
          ) {
            drawHoverDot(
              ctx,
              hotSpotPosition.x,
              hotSpotPosition.y,
              getColorRGB(hotSpotColor),
              spaceRadius
            );
          }

          store.buildingStore.floorSpaces[propsFloorId]
            .filter((spt) => deskTypeIds.includes(spt.space_type?.id || 0))
            .map((spc, idx) => {
              if (typeof deskFilters !== "undefined" && deskFilters.length == 1) {
                // Single filter
                let space_tags = toJS(spc.tags);

                if (space_tags && space_tags.length > 0) {
                  let exists = false;
                  space_tags.forEach((name, index) => {
                    if (Object.values(deskFilters).indexOf(name) > -1) {
                      exists = true;
                    }
                  });

                  if (exists) {
                    drawSpaceSpots(spc, idx, ctx);
                  }
                } else if (
                  spc.space_type?.name &&
                  Object.values(deskFilters).indexOf(spc.space_type?.name) > -1
                ) {
                  drawSpaceSpots(spc, idx, ctx);
                }
              } else if (typeof deskFilters !== "undefined" && deskFilters.length > 1) {
                //multiple filter

                let space_tags = toJS(spc.tags);

                if (space_tags && space_tags.length > 0) {
                  let exists_count = 0;
                  space_tags.forEach((name, index) => {
                    if (Object.values(deskFilters).indexOf(name) > -1) {
                      exists_count = exists_count + 1;
                    }
                  });

                  if (exists_count == deskFilters.length) {
                    drawSpaceSpots(spc, idx, ctx);
                  }
                } else {
                  let exists_count = 0;
                  deskFilters.forEach((name, index) => {
                    if (name == spc.space_type?.name) {
                      exists_count = exists_count + 1;
                    }
                  });

                  if (exists_count == deskFilters.length) {
                    drawSpaceSpots(spc, idx, ctx);
                  }
                }
              } else {
                drawSpaceSpots(spc, idx, ctx);
              }
            });

          if (hotSpotPosition && hotSpotPosition.space) {
            const seatNames = getBookedSpaceName(
              isWayFinder
                ? getBookingsAtThisTime(floorbookings, wayFinderPeriod, isWayFinder ? true : false)
                : floorbookings,
              hotSpotPosition.space.id,
              null
            );
            const tooltip = seatNames.length === 1 ? seatNames[0] : seatNames.join("\n");
            if (tooltip) {
              drawLabel(
                ctx,
                tooltip,
                hotSpotPosition.x,
                hotSpotPosition.y - spaceRadius * 3,
                false,
                true,
                getColorRGB(label_tooltip.text),
                getColorRGB(label_tooltip.background),
                getColorRGB(label_tooltip.border)
              );
            }
            if (labels) {
              drawLabel(
                ctx,
                hotSpotPosition.space.name,
                hotSpotPosition.x,
                hotSpotPosition.y - spaceRadius * 1.5,
                true,
                false,
                getColorRGB(hotSpotColor),
                null,
                null
              );
            }
          }
        }
        setIsDrawn(true);
      }
      // if (store.buildingStore.currentBuilding) {
      //   console.log("from useEffect()................",store.buildingStore.currentBuilding);
      //   const hasSpace = hasBookableSpace(store.buildingStore.currentBuilding);
      //   console.log("calling setCanBook....");
      //   setCanBook(hasSpace);
      //   console.log("calling setIsBookable....");
      //   setIsBookable(hasSpace);
      // }
    }
  };

  const drawSpaceSpots = (spc, idx, ctx) => {
    if (spc.coordinates || (spc.corrected_coordinates_x && spc.corrected_coordinates_y)) {
      const correctedPoint = getPoint(spc, theScales);
      const isFireWarden = isFireWardenBooked(
        isWayFinder
          ? getBookingsAtThisTime(floorbookings, wayFinderPeriod, isWayFinder ? true : false)
          : floorbookings,
        spc.id
      );
      const isFirstAid = isFirstAidOfficerBooked(
        isWayFinder
          ? getBookingsAtThisTime(floorbookings, wayFinderPeriod, isWayFinder ? true : false)
          : floorbookings,
        spc.id
      );
      const isMyTeam = inThisTeam(spc.user_id);

      const theColor = getColor(
        spc,
        false,
        null,
        getBoolean(`${spc.id}` === `${propsSpaceId}` && didClick && clickedSpace),
        floorbookings,
        deskHours,
        buildingBookingSpecs.gap,
        buildingBookingSpecs.min_duration,
        store.userStore.me!.id,
        isWayFinder,
        wayFinderPeriod
      );

      if (theColor.hex == "#66AA72" && isWayFinder == undefined) {
        props.spaceCount(1);
      }

      if (labels && idx % 3 === 2)
        drawLabel(
          ctx,
          spc.name,
          correctedPoint.x,
          correctedPoint.y - spaceRadius * 1.5,
          true,
          false,
          getColorRGB(theColor),
          null,
          null
        );
      if (!labels) {
        const outerColor =
          isMyTeam && `${spc.user_id}` !== `${store.userStore.me?.id}` ? spot_team.hex : null;
        drawSpot(
          ctx,
          { ...correctedPoint, id: spc.id },
          getColorRGB(theColor),
          clickedSpace,
          outerColor,
          spaceRadius,
          spc.id === showSpaceId,
          isFavourite(null, spc.id),
          spc.space_type?.id
        );
      }
      if (isFirstAid)
        drawCross(
          ctx,
          isFireWarden ? correctedPoint.x - 16 : correctedPoint.x - 12,
          correctedPoint.y - 15,
          bhpColor.firstAid,
          isFireWarden ? 22 : 28
        );
      if (isFireWarden)
        drawCross(
          ctx,
          isFirstAid ? correctedPoint.x - 2 : correctedPoint.x - 12,
          correctedPoint.y - 15,
          bhpColor.fireWarden,
          isFirstAid ? 22 : 28
        );
    }
  };

  /**
   * getSpaceProperties
   * some spaces have a list of tags describing the space,
   * e.g "Desk -Webcam, Desk - Large monitor"
   * others just have a space.type.name e.g"Quiet Corral"
   * this function jets rid of Desk - from the tag and sorts
   * based on a list we were given
   * @param spc - the space
   * @returns sorted and tweaked .tags[] or space.type.name
   * if not tags exist
   */
  const getSpaceProperties = (spc: Space) => {
    const deskSortOrder = [
      "Anchor - OHS",
      "Anchor - Technology",
      "Desk - Fixed Height",
      "Desk - Sit/Stand",
      "Desk - Ergotron Sit/Stand",
      "Desk - Webcam",
      "Desk - Dockless Monitors",
      "Desk - Dockless Monitor",
      "Desk - Large Monitor",
      "Desk - P50 Dock",
      "Desk - HP Technical Dock",
      "Desk - Thunderbolt Dock",
      "Desk - Non-Standard Workstation",
      "Desk Non-Standard Workstation",
    ];

    const sortDeskTag = (a: any, b: any) =>
      deskSortOrder.indexOf(a) > deskSortOrder.indexOf(b) ? 1 : -1;

    const rawTags = spc.tags;
    let tags: string = "";
    if (rawTags) {
      const re = /Desk - /gi;
      tags = rawTags
        .slice()
        .sort((a: string, b: string) => sortDeskTag(a, b))
        .map((tg: string) => t(tg.replace(re, "")))
        .join(", ");
    }
    return tags !== "" ? tags : spc.space_type?.name || "";
  };

  const getNoDaysBetween = (date1: Date | undefined, date2: Date | undefined) => {
    if (!date1 || !date2) return 0;
    if (date1 === date2) return 0;

    // Set the time to midnight for current date
    date1.setHours(23, 45, 0, 0);
    const difference = date2.getTime() - date1.getTime();
    const days = Math.ceil(difference / (1000 * 3600 * 24));
    return days - 1;
  };

  /**
   * Returns booked name, if multiple Multiple Bookings
   * or null if not given (or null)
   * Unavailable if names aren't available
   * @param bookings
   * @param space_id - the id of the space to check
   * @param specificTime - the time to check
   */
  const getBookedSeatName = (
    bookings: BasicBooking[],
    space_id: number,
    specificTime: Period | null,
    periodStatus: string | null = null
  ) => {
    const spaceBookings = bookings.filter((bkg) => bkg.space && bkg.space.id === space_id).slice();
    const spaceStatus = periodStatus
      ? periodStatus
      : specificTime
      ? bookingPeriodStatus(
          bookings,
          specificTime,
          space_id,
          false,
          false,
          false,
          buildingBookingSpecs.gap,
          buildingBookingSpecs.min_duration
        )
      : spaceBookings && spaceBookings.length > 0
      ? "111"
      : "000";

    switch (spaceStatus) {
      case "000":
        return ["Available"];
      case "111":
        // if only one booking and name availabe return name
        // else return Unavailable
        return spaceBookings.length === 1 ? [spaceBookings[0].user_name] : ["Unavailable"];
      case "100":
      case "010":
      case "001":
      case "110":
      case "101":
      case "011":
      case "001":
        return ["Partly Available"];
      default:
        return specificTime
          ? isTimeAvailable(
              spaceBookings,
              specificTime,
              space_id,
              buildingBookingSpecs.min_duration,
              buildingBookingSpecs.max_duration,
              buildingBookingSpecs.gap
            )
            ? ["Partly Available"]
            : ["Unavailable"]
          : ["Partly Available"];
    }
  };

  const [canBook, setCanBook] = useState(true);
  const createBooking = (spc: Space | null, myBooking: boolean = false) => {
    if (canBook) {
      if (myBooking && spc) {
        setDeleteConfirmDialog(true);
        if (date) setDeleteConfirmSchedule({ space: spc, date: date });
      } else {
        if (bookingStart && bookingEnd) {
          let endDateOfBooking = date;
          let endTimeOfBooking = bookingEnd;
          if (bookingEnd.indexOf("+") > -1) {
            // future date...
            const timeParts = bookingEnd.replace("+", "").split(" ");
            const dayAdvance = Number(timeParts[0]);
            endDateOfBooking = moment(endDateOfBooking).add(dayAdvance, "day").format("YYYY-MM-DD");
            endTimeOfBooking = timeParts[1];
          }
          const startDate = date;
          const startTime = bookingStart;
          const endDate = endDateOfBooking;
          const endTime = endTimeOfBooking;
          console.log("booking detail: ", startDate, startTime, endDate, endTime);
          doBooking(startDate, startTime, endDate, endTime);
        }
      }
    }
  };

  //  const currentBuildingId = store.buildingStore.currentBuilding?.id;
  const theDate = date ? date : moment(new Date()).format("YYYY-MM-DD");

  const [clickedSpace, setClickedSpace] = useState<Space | null>(null);
  const [isDrawn, setIsDrawn] = useState(true);
  const [isDrawing, setIsDrawing] = useState(false);
  const [isBookable, setIsBookable] = useState(true);
  // States for validation
  const [daysToBooking, setDaysToBooking] = useState<number>(0);
  const [tooFarInAdvance, setTooFarInAdvance] = useState<boolean>(false);
  const [isValidating, setIsValidating] = useState<boolean>(false);
  let disableBookingButton = false;
  let reasonText: string[] = [];

  const [favouriteSpaces, setFavouriteSpaces] = useState([...store.userStore.favouriteSpaces]);
  const isFavourite = (space: Space | null, spcId: number | null) => {
    if (!space && !spcId) return false;
    return spcId ? store.userStore.isFavouriteSpace(spcId) : false;
  };
  const addFavourite = async (spaceId?: number) => {
    if (spaceId) await store.userStore.addFavouriteSpaces(spaceId);
  };
  const removeFavourite = async (spaceId?: number) => {
    if (spaceId) await store.userStore.removeFavouriteSpaces(spaceId);
  };

  const [hasLocalPoints, setHasLocalPoints] = useState(false);

  // --- Booking Date Validation ---
  useEffect(() => {
    const validateBookingDate = async () => {
      if (!deskHours?.start) return;

      setIsValidating(true);
      try {
        // Fetch current UTC datetime from the backend.
        const currentUTC: Date = await fetchCurrentDateFromBackend();

        // Convert the booking start (which is in local time) to UTC using the building's timezone.
        const bookingUTC: Date = convertLocalToUTC(deskHours.start, timezone);

        // Calculate the difference in days between currentUTC and bookingUTC.
        const days = getNoDaysBetweenUTC(currentUTC, bookingUTC);
        const tooFar = days > MAX_DAYS_AHEAD_TO_BOOK;

        setDaysToBooking(days);
        setTooFarInAdvance(tooFar);

        if (tooFar) {
          disableBookingButton = true;
          reasonText.push(t("Too far in advance"));
        }
      } catch (error) {
        console.error("Validation error:", error);
        // Fallback: use browser date if the backend call fails.
        const currentBrowserDate = new Date();
        const bookingUTC = convertLocalToUTC(deskHours.start, timezone);
        const days = getNoDaysBetweenUTC(currentBrowserDate, bookingUTC);
        const tooFar = days > MAX_DAYS_AHEAD_TO_BOOK;
        setDaysToBooking(days);
        setTooFarInAdvance(tooFar);
        if (tooFar) {
          disableBookingButton = true;
          reasonText.push(t("Too far in advance"));
        }
      } finally {
        setIsValidating(false);
      }
    };

    validateBookingDate();
  }, [deskHours, timezone, t]);

  useEffect(() => {
    setCanvasHeight(canvasWidth * yRatio);
  }, [yRatio]);

  useEffect(() => {
    if (store.buildingStore.currentFloor) {
      // provide some feeback via console if things aren't right
      const theFloorSpaces = store.buildingStore.floorSpaces
        ? store.buildingStore.floorSpaces[store.buildingStore.currentFloor.id]
        : [];
      const hasLocalCoordinates =
        theFloorSpaces && theFloorSpaces.length > 0
          ? theFloorSpaces[0].corrected_coordinates_x
          : false;

      const hasBackendImage =
        store.buildingStore.currentFloor?.floor_plan &&
        store.buildingStore.currentFloor?.floor_plan !== ""
          ? true
          : false;
      if (!hasBackendImage)
        console.log(`Floor ${store.buildingStore.currentFloor.id}: Legacy Floorplan Image`);
      if (!hasLocalCoordinates) {
        setHasLocalPoints(false);
      } else setHasLocalPoints(true);

      const floorImageUrl = hasBackendImage
        ? store.buildingStore.currentFloor?.floor_plan
        : getFloorImageModule(store.buildingStore.currentFloor.id);
      setFloorImage(floorImageUrl);
    }
    closeInfoBox();
  }, [store.buildingStore.currentFloor]);

  useEffect(() => {
    closeInfoBox();
    store.bookingStore
      .loadFloorBookings(dayPeriod, `${store.buildingStore.currentFloorId}`)
      .then(() => {
        const store_floor_bookings: BasicBooking[] =
          store.bookingStore.floor_bookings[`${store.buildingStore.currentFloorId}`];
        if (store_floor_bookings) setFloorBookings(store_floor_bookings.slice());
        setIsDrawn(false);
      });
  }, [store.buildingStore.currentFloorId, store.bookingStore.dirtyFloor]);

  useEffect(() => {
    if (buildingId && !store.buildingStore.isLoadingSpaces && propsFloorId) {
      store.buildingStore.loadSpaces(buildingId, Number(propsFloorId), theDate).then(() => {
        const floorSpaces =
          store.buildingStore.currentFloor &&
          store.buildingStore.floorSpaces &&
          store.buildingStore.floorSpaces[store.buildingStore.currentFloor.id];
        const hasLocalPoints =
          floorSpaces && floorSpaces.length > 0
            ? floorSpaces[0].coordinates.x
              ? true
              : false
            : false;
        setHasLocalPoints(hasLocalPoints);
      });
    }
  }, []);

  useEffect(() => {
    if (store.buildingStore.currentBuilding) {
      console.log("Inside useEffect()....", store.buildingStore.currentBuilding);
      const hasSpace = hasBookableSpaceCapacity(store.buildingStore.currentBuilding, theDate);
      setCanBook(hasSpace);
      setIsBookable(hasSpace);
    }
  }, [store.buildingStore.currentBuilding, isBookable]);

  useEffect(() => {
    if (context && !store.buildingStore.isLoadingFloorPlan && !isDrawing && !isDrawn) {
      setIsDrawing(true);
      draw(context);
    }
  }, [
    store.buildingStore.isLoadingSpaces,
    clickPosition,
    clickedSpace,
    isDrawn,
    favouriteSpaces,
    deskFilters,
  ]);

  useEffect(() => {
    if (
      isDrawn &&
      propsFloorId &&
      propsSpaceId &&
      store.buildingStore.floorSpaces &&
      store.buildingStore.floorSpaces[propsFloorId]
    ) {
      const theFloorSpaces = store.buildingStore.floorSpaces[propsFloorId];
      const spc = theFloorSpaces.find((spc) => spc.id === propsSpaceId);
      if (spc) {
        const thePoint = getPoint(spc, getScales(propsFloorId, floorplanSpecs));
        if (thePoint) {
          setClickPosition(thePoint);
          setClickedSpace(spc);
        }
      }
    }
  }, [isDrawn]);

  const box = { height: 104, width: 300, buttonAllowance: 35 };
  const closeInfoBox = () => {
    setClickedSpace(null);
    setClickPosition({ x: -1000, y: 0 });
  };

  const getDisabledStatus = (
    isBookable: boolean,
    myBooking: boolean,
    bookingPeriodAvailable: boolean,
    disableEditing: boolean,
    isDoubleBooked: boolean
  ) => {
    return !isBookable || isDoubleBooked
      ? true
      : disableEditing
      ? disableEditing
      : myBooking
      ? false
      : !bookingPeriodAvailable;
  };

  const showServiceRequest = true;
  const [supportRequestOpen, setSupportRequestOpen] = useState(false);
  const getServiceRequestContent = () => {
    return (
      <Box>
        <ul>
          <li>
            <strong>{t("Technology request")}</strong>, {t("lodge a service request in")}{" "}
            <a
              href="https://bhp.service-now.com/sp/?id=sc_cat_item&sys_id=4b63e79adb6a43004b46fc1dbf9619ec"
              target="_blank"
            >
              {t("ServiceNow")}
            </a>
          </li>
          {}
          <li>
            <strong>{t("Workplace request")}</strong>, {t("open a support request in")}{" "}
            <a href="https://teams.apptreeio.com/team/cw_BHP_prod" target="_blank">
              {t("Property Assist")}
            </a>
          </li>
        </ul>
      </Box>
    );
  };

  const [cantBookPopupOpen, setCantBookPopupOpen] = useState(false);
  const [cantBookPopupText, setCantBookPopupText] = useState(<span></span>);

  const showErrors = (reason: string[]) => {
    const reasonText =
      moment(bookingStart).isValid() && moment(bookingEnd).isValid() ? (
        <div>
          <p>
            {t("Sorry, a booking can't be made between")} {moment(bookingStart).format("h:mma")} and{" "}
            {moment(bookingEnd).format("h:mma")} {t("for the following reasons")};
          </p>
          <span style={{ whiteSpace: "pre-line" }}>{reason.join("\n")}</span>
        </div>
      ) : (
        <div>
          <p>{t("Sorry, a booking can't be made at the moment for the following reasons")}; </p>
          <span style={{ whiteSpace: "pre-line" }}>{reason.join("\n")}</span>
        </div>
      );
    setCantBookPopupText(reasonText);
    setCantBookPopupOpen(true);
  };

  const infoBox = () => {
    const spc = clickedSpace;
    if (spc) {
      const spaceBookings = floorbookings.filter((bkg) => bkg.space && bkg.space.id === spc.id);

      // const canBookThisPeriod = deskHours
      //   ? isTimeAvailable(
      //       spaceBookings,
      //       deskHours,
      //       spc.id,
      //       buildingBookingSpecs.min_duration,
      //       buildingBookingSpecs.max_duration,
      //       buildingBookingSpecs.gap
      //     )
      //   : false;

      // const myBookings = spaceBookings.filter((bkg) => bkg.user_id === store.userStore.me!.id);
      // const myBooking = bookingSelected
      //   ? bookingSelected.user_id === store.userStore.me!.id
      //   : false;
      // const bookingPeriodAvailable = deskHours
      //   ? isTimeAvailable(
      //       spaceBookings,
      //       deskHours,
      //       spc.id,
      //       buildingBookingSpecs.min_duration,
      //       buildingBookingSpecs.max_duration,
      //       buildingBookingSpecs.gap
      //     )
      //   : false;

      const hasMultipleBookings = spaceBookings && spaceBookings.length > 0;

      const bookingStartString = `${date} ${bookingStart}`;
      const bookingEndString = getEndString(bookingEnd, date);

      const booking_period_status = getPeriodStatus(
        spaceBookings,
        bookingStartString,
        bookingEndString
      );

      const mySpacesAlreadyBooked = bookingsAtThisTime(
        getBookingsForDate(
          store.bookingStore.user_bookings,
          date!,
          store.buildingStore.currentBuilding?.building_timezone
        ),
        bookingStartString,
        bookingEndString,
        store.buildingStore.currentBuilding?.building_timezone
      );

      const isDoubleBooked =
        mySpacesAlreadyBooked && mySpacesAlreadyBooked.length > 0 ? true : false;

      const isBookable = true;
      const myBooking = false;
      const bookingPeriodAvailable = booking_period_status === "000";
      const editingDisabled = disableEditing || false;

      const lop = getLengthOfSelection(bookingStartString, bookingEndString);
      const tooShort =
        lop < (store.buildingStore.currentBuilding?.minimum_booking_time || BOOKING_DURATION_MIN);
      const tooLong =
        lop > (store.buildingStore.currentBuilding?.maximum_booking_time || BOOKING_DURATION_MAX);
      const invalidLength = tooShort || tooLong;

      const endBeforeStart =
        moment(bookingStartString).toDate() > moment(bookingEndString).toDate();

      let disableBookingButton = getDisabledStatus(
        isBookable,
        myBooking,
        bookingPeriodAvailable,
        editingDisabled,
        isDoubleBooked
      );

      if (invalidLength) disableBookingButton = true;
      if (endBeforeStart) disableBookingButton = true;
      if (!canBook) disableBookingButton = true;

      let reasonText: string[] = [];
      if (!canBook) {
        console.log("Inside current capacity issue IF block");
        reasonText.push(
          `${t("No spaces available to book. Current Capacity:")}: ${
            store.buildingStore.currentBuilding?.adjusted_capacity
          }`
        );
      }
      if (tooShort)
        reasonText.push(
          `${t("Too short - minimum length")} ${
            store.buildingStore.currentBuilding?.minimum_booking_time
          } ${t("minutes")}`
        );
      if (tooLong)
        reasonText.push(
          `${t("Too long")} -  ${store.buildingStore.currentBuilding?.maximum_booking_time} ${t(
            "minutes"
          )}`
        );
      if (endBeforeStart) reasonText.push(t("End time before start time"));
      if (!isBookable) reasonText.push(t("Not bookable"));
      if (!bookingPeriodAvailable) reasonText.push(t("Period not available"));
      if (disableEditing) reasonText.push(t("Editing disabled"));
      if (isDoubleBooked)
        reasonText.push(
          `${t("You have other bookings")} (${mySpacesAlreadyBooked.length}) ${t("at this time")}`
        );

      const bookedText = deskHours
        ? getBookedSeatName(floorbookings, spc.id, deskHours, booking_period_status)
        : "";

      // const daysToBooking = getNoDaysBetween(new Date(), deskHours?.start);
      // const tooFarInAdvance = daysToBooking > MAX_DAYS_AHEAD_TO_BOOK;

      if (tooFarInAdvance) {
        disableBookingButton = true;
        reasonText.push(t("Too far in advance"));
      }

      const theColor = getColor(
        spc,
        false,
        booking_period_status,
        getBoolean(`${spc.id}` === `${propsSpaceId}` && didClick && clickedSpace),
        floorbookings,
        deskHours,
        buildingBookingSpecs.gap,
        buildingBookingSpecs.min_duration,
        store.userStore.me!.id,
        isWayFinder,
        wayFinderPeriod
      );
      const removeFavouriteText = t("Click to remove favourite");
      const addFavouriteText = t("Click to make favourite");
      const otherBookingText = t("You have other bookings at this time");
      const serviceRequestText = t("Open Service Request Menu");
      const bookDeskTooltipText = t("Book Desk");
      return (
        <div
          ref={infoBoxRef}
          style={{
            position: "absolute",
            top: clickPosition.y - box.height,
            left: clickPosition.x,
            border: `2px solid ${spc ? theColor.hex : "black"}`,
            borderRadius: `4px`,
            backgroundColor: "white",
            minHeight: box.height,
            width: box.width,
            whiteSpace: "pre-line",
            fontSize: "15px",
            padding: 10,
            marginBottom: box.height * -1,
            zIndex: "15",
          }}
        >
          <Grid container>
            <Grid item xs={11}>
              <div style={{ marginBottom: 8, fontWeight: 700 }}>{spc?.name || "A.0.000"}</div>
            </Grid>
            <Grid item xs={1}>
              <Box>
                {" "}
                <IconButton
                  aria-label="close"
                  style={{
                    position: "absolute",
                    right: 2,
                    top: -2,
                    color: bhpColor.grey2,
                  }}
                  onClick={closeInfoBox}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Grid>

            {spc.assigned_org_unit && (
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    {<strong>{t("Team")}:</strong>}
                    {` ${spc.assigned_org_unit.name}`}
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  {<strong>{t("Booking Status")}:</strong>}
                  {
                    <span
                      style={{
                        color: theColor.hex,
                      }}
                    >{` ${bookedText}`}</span>
                  }
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4}>
                  {<strong>{t("Properties")}:</strong>}
                </Grid>
                <Grid item xs={8}>
                  {spc && getSpaceProperties(spc)}
                </Grid>
              </Grid>
            </Grid>
            {hourlyBookings && hasMultipleBookings && (
              <Grid item xs={12} style={{ marginTop: "8px" }}>
                <Grid container>
                  <Grid item xs={12}>
                    {<strong>{t("Current Bookings")}:</strong>}
                  </Grid>
                  <Grid item xs={12} style={{ fontSize: "14px" }}>
                    {spc &&
                      drawBookings(
                        spc,
                        date,
                        store.buildingStore.currentBuilding?.building_timezone
                      )}
                  </Grid>
                </Grid>
              </Grid>
            )}
            {showButton !== false && hourlyBookings && (
              <Grid item xs={12} style={{ marginTop: "8px" }}>
                <Grid container>
                  <Grid item xs={12}>
                    {<strong>{t("Desk Hours")}:</strong>}
                  </Grid>
                  <Grid container>
                    <Grid item xs={6}>
                      {<strong>{t("From")}</strong>}
                      {startSelector}
                    </Grid>
                    <Grid item xs={6}>
                      {<strong>{t("To")}</strong>} {endSelector}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
              <Grid container>
                <Grid item xs={5} style={{ display: "flex", alignItems: "center" }}>
                  {isFavourite(null, spc.id) ? (
                    <Box onClick={() => removeFavourite(spc.id)} style={{ cursor: "pointer" }}>
                      <Tooltip title={removeFavouriteText}>
                        <StarTwoToneIcon
                          color="primary"
                          style={{ display: "flex", alignSelf: "center" }}
                        />
                      </Tooltip>
                    </Box>
                  ) : (
                    <Box onClick={() => addFavourite(spc.id)} style={{ cursor: "pointer" }}>
                      <Tooltip title={addFavouriteText}>
                        <StarBorderTwoToneIcon style={{ display: "flex", alignSelf: "center" }} />
                      </Tooltip>
                    </Box>
                  )}
                  {showServiceRequest && <ServiceContextMenu t={t} />}
                </Grid>
                <Grid item xs={7}>
                  {showButton !== false && (
                    <Tooltip
                      style={{ whiteSpace: "pre-line" }}
                      title={
                        isDoubleBooked
                          ? otherBookingText
                          : !disableBookingButton
                          ? bookDeskTooltipText
                          : reasonText.join(",\r\n")
                      }
                    >
                      <Box
                        textAlign="end"
                        mt={1}
                        onClick={
                          disableBookingButton
                            ? () => {
                                showErrors(reasonText);
                              }
                            : undefined
                        }
                      >
                        <Button
                          onClick={() => {
                            if (
                              !store.scheduleStore.isChangingSchedule &&
                              store.buildingStore.currentFloor &&
                              spc &&
                              spc.id
                            ) {
                              store.buildingStore.setCurrentSpace(
                                store.buildingStore.currentFloor.id,
                                spc.id
                              );
                              createBooking(spc, myBooking);
                            }
                          }}
                          variant="contained"
                          color="primary"
                          style={{
                            // color: isDoubleBooked ? "white" : "",
                            // backgroundColor: isDoubleBooked
                            //   ? bhpColor.partly_available
                            //   : bhpColor.orange1,
                            textTransform: "none",
                            height: "24px",
                            minWidth: "104px",
                          }}
                          disableElevation
                          disabled={disableBookingButton}
                        >
                          {store.scheduleStore.isChangingSchedule ? (
                            <CircularProgress style={{ color: "white" }} size={14} />
                          ) : myBooking && bookingSelected ? (
                            t("Cancel")
                          ) : (
                            t("Book")
                          )}
                        </Button>
                      </Box>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    }
  };

  // #region Stuff for scaling
  const offsetIncrement = 1;
  const scaleIncrement = 0.001;

  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false);
  const [deleteConfirmSchedule, setDeleteConfirmSchedule] = useState<{
    space: Space;
    date: string;
  } | null>(null);

  const [teamPopupOpen, setTeamPopupOpen] = useState(false);
  const [bookStatusPopupOpen, setBookStatusPopupOpen] = useState(false);
  const [propertiesPopupOpen, setPropertiesPopupOpen] = useState(false);

  //#region Effects - Side Effects to State change

  const [floorbookings, setFloorBookings] = useState<BasicBooking[]>(
    propsFloorId ? store.bookingStore.getFloorBookings(propsFloorId) : []
  );

  // looking for a change in desk hours

  const getBookingStringFromMoment = (time: Date, timeZone?: string) => {
    return timeZone
      ? moment(time).tz(timezone).format("YYYY-MM-DD HH:mm")
      : moment(time).format("YYYY-MM-DD HH:mm");
  };

  //#endregion Effects
  const [rangeValue, setRangeValue] = React.useState(9);
  const handleRangeChange = (newValue) => {
    const timeParts = `${newValue}`.split(".");
    const startHour = Number(timeParts[0]);
    let endHour = startHour;
    let startMin = "00";
    let endMin = "15";
    if (timeParts.length > 0) {
      switch (timeParts[1]) {
        case "25":
          startMin = "15";
          endMin = "30";
          break;
        case "50":
          startMin = "30";
          endMin = "45";
          break;
        case "75":
          startMin = "45";
          endMin = "00";
          endHour = startHour + 1;
          break;
      }
    }
    const sHour = `${startHour < 10 ? "0" : ""}${startHour}`;
    const eHour = `${endHour < 10 ? "0" : ""}${endHour}`;
    const startTime = moment.tz(`${date} ${sHour}:${startMin}:00`, timezone).toDate();
    const endTime = moment.tz(`${date} ${eHour}:${endMin}:00`, timezone).toDate();
    setWayFinderPeriod({ start: startTime, end: endTime });
    setRangeValue(newValue);
  };

  return (
    <Box
      pb={{ xs: 2, md: 4 }}
      pt={{ xs: 0, md: 2 }}
      px={{ xs: 0, md: 0 }}
      style={{ display: "block", position: "absolute" }}
    >
      {propsFloorId && store.buildingStore.floorSpaces[propsFloorId]
        ? drawExtras(store.buildingStore.floorSpaces[propsFloorId])
        : undefined}
      {drawKey(isWayFinder, wayFinderPeriod, rangeValue, handleRangeChange)}
      {!floorImage ? (
        <Box>Floorplan image ({propsFloorId}) not found</Box>
      ) : (
        <>
          <CanvasWrapper
            setcontext={theContext}
            mouseclick={mouseClick}
            draw={draw}
            height={canvasHeight}
            width={canvasWidth}
            mousemove={checkMouseLocation}
          />
          {propsFloorId &&
            store.buildingStore.currentBuilding?.floors?.find(
              (flr) => `${flr.id}` === `${propsFloorId}`
            ) && (
              <img
                src={propsFloorId && floorImage}
                width={canvasWidth}
                onLoad={onImgLoad}
                style={{ position: "absolute", left: -5000 }}
              />
            )}
        </>
      )}
      {infoBox()}
      <ConfirmDialog
        title={t("Change of plan?")}
        primaryButtonText={t("Remove")}
        secondaryButtonText={t("Keep")}
        handleSecondaryClick={() => setDeleteConfirmDialog(false)}
        handlePrimaryClick={async () => {
          if (deleteConfirmSchedule)
            await store.scheduleStore.cancelDesk(deleteConfirmSchedule.date);
          if (buildingId && floorId && theDate)
            await store.buildingStore.loadSpaces(buildingId, floorId, theDate, true);
          closeInfoBox();
          setDeleteConfirmDialog(false);
          if (onclose) {
            onclose();
          } else {
            history.push("/my-planner/bookings");
          }
        }}
        isOpen={deleteConfirmDialog}
      >
        {deleteConfirmSchedule && (
          <p>
            {t("Remove your desk booking for")}{" "}
            <Box fontWeight="bold" color="primary.main" component="span">
              {deleteConfirmSchedule.space?.name}
            </Box>{" "}
            <Box fontWeight="bold" component="span">
              {deleteConfirmSchedule.space?.building?.city}
            </Box>
            {", "}
            <Box component="span">{deleteConfirmSchedule.space.building?.building_name}</Box>
            {` ${t("on")} `}
            {`${t(moment(deleteConfirmSchedule!.date).format("dddd"))}, ${t(
              moment(deleteConfirmSchedule!.date).format("Do")
            )} ${t(moment(deleteConfirmSchedule!.date).format("MMMM"))}`}
            {`${t("?")} `}
          </p>
        )}
      </ConfirmDialog>
      <Dialog TransitionComponent={Transition} open={supportRequestOpen}>
        <DialogTitle className={classes.dialogTitle}>Lodge a support request</DialogTitle>
        <DialogContent>{getServiceRequestContent()}</DialogContent>
        <DialogActions style={{ justifyContent: "end", padding: settings.outerPadding }}>
          <Button
            onClick={() => setSupportRequestOpen(false)}
            variant="text"
            className={classes.cancelButton}
            disableElevation
            color="secondary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <PopupInformationWidget
        t={t}
        isOpen={teamPopupOpen}
        handleClose={() => setTeamPopupOpen(false)}
        handleSecondaryClick={() => setTeamPopupOpen(false)}
        title={t("Assigned Team")}
        content={t("Team Info")}
        buttonText={t("Okay")}
        secondaryButtonText={null}
      />
      <PopupInformationWidget
        t={t}
        isOpen={bookStatusPopupOpen}
        handleClose={() => setBookStatusPopupOpen(false)}
        handleSecondaryClick={() => setBookStatusPopupOpen(false)}
        title={t("Booking Status")}
        content={t("Book Properties Info")}
        buttonText={t("Okay")}
        secondaryButtonText={null}
      />
      <PopupInformationWidget
        t={t}
        isOpen={propertiesPopupOpen}
        handleClose={() => setPropertiesPopupOpen(false)}
        handleSecondaryClick={() => setPropertiesPopupOpen(false)}
        title={t("Desk Properties")}
        content={t("Desk Properties Info")}
        buttonText={t("Okay")}
        secondaryButtonText={null}
      />
      <Dialog TransitionComponent={Transition} open={cantBookPopupOpen}>
        <DialogTitle
          className={classes.dialogTitle}
          style={{ display: "flex", alignItems: "center" }}
        >
          <WarningTwoToneIcon />{" "}
          {moment(bookingStart).isValid() && moment(bookingEnd).isValid()
            ? t("Can't book those times")
            : t("Can't book")}
        </DialogTitle>
        <DialogContent>{cantBookPopupText}</DialogContent>
        <DialogActions style={{ justifyContent: "end", padding: settings.outerPadding }}>
          <Button
            onClick={() => setCantBookPopupOpen(false)}
            variant="text"
            className={classes.cancelButton}
            disableElevation
            color="secondary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
});

export default InteractiveFloorplan;
